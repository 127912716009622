@font-face { 
  font-family: "BitterRegular";
  src: url("./fonts/Bitter/static/Bitter-Regular.ttf"); 
  font-style: normal;
  font-weight: 400;
}

/* .background_pic_div {
  background-color: #282c34;
} */

.App {
  text-align: center; 

  font-family: 'Comfortaa', cursive;

}

.under_header_div {  
  margin-left: 19px;
  margin-right: 30px;
  height: 30px;
 
  height: fit-content;
  width: fit-content;
  display: flex;
  justify-content: space-between;

  border-top: #367669 solid;
  border-width: 2px;
}

.under_header_div p {
  color: #282c34;
}

.under_header_buttons_div {
  display: flex;
  /* width: 577px; */
}

.App-link {
  color: #61dafb;
}

.content {
  background-color: white;
 
  height: auto;
  min-height: 100vh;
  width: 100vw;  
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  font-size: 17px;
  color: white;
  background:rgba(255,255,255, 0.7);
  font-family: 'Comfortaa';
}



.header {
  min-height: 250px;  
  background: rgba(255, 255, 255, 0.7);
  display: flex;  
  padding: 30px; 
}

.logo_wrapper {
  width: 300px;
  height: 270px;  
}
.logo img{
  object-fit: cover;
  width: 100%;
  height: 100%;
  padding-left: 30px;
  padding-top: 8px;
}

.header_pic_wrapper {
  display: flex;
  width: 300px;
  height: 300px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px; 
}
.header_pic img{
  object-fit: cover;
  width: 50vh; 
  padding-left: 30px;
  padding-top: 8px;
}

.header_pic{
    display: flex;
    justify-content: center;
    margin: 15px;
    padding: 10px;
}

.contacts_text {
  display: flex;
  justify-content: space-between;  
  align-items: end; 
  margin-left: 115px;
  
}
.upper_div {
  justify-content:space-around;
}
.contact_string{
  display: flex;
  justify-content: space-between;
}

.contact_pic {
  display: flex;
  align-items: center;
}

.contact_string img {
  color: #367669;
  margin-right: 10px;
  width: 35px;
  height: 35px;
}

.contact_string_email_p {
  display: flex;
}

.contact_string_p {
  width: 147px;
  color: black;
  margin: auto; 
  font-size: 21px;
}

.contact_string_p a {
  margin: auto;
  color: black;
}

.phonenum {
  text-decoration: none;
}

.button_div {  
  width: 25%;
  width: fit-content;
  height: fit-content;

  cursor:pointer;  
  background-color:gray;  
  text-align:center;
  color:#ffffff; 
  font-size: 20px;
 
  background:rgba(255,255,255, 0.05);
  padding-left: 10px; /* Минимальный отступ текста от границ кнопки. Определяет размер кнопки */
  padding-right: 10px;
  border-radius: 5px; /* Радиус закругления уголков */
  box-shadow: 0px 1px 3px; /* Тень */ 

  white-space: nowrap;
  }

  .button_div a{
    padding-left: 10px; /* Минимальный отступ текста от границ кнопки. Определяет размер кнопки */
    padding-right: 10px;
    color: black; 
    text-decoration:none; 
  }
  
.button_div:hover { /* Преображение при наведении */
  /* background-image: radial-gradient(ellipse farthest-corner at 50% 50%, #a8fdfd,#367669);  Радиальный градиент, переход цвета от центра к краям */
  background:rgba(54, 118, 105, 0.3);
}

.language_switcher {
  height: 50px;
  width: 171px
}

.language_switcher p {
  margin: auto;
}

.file_download {
  background:rgba(54, 118, 105, 0.3);
  text-decoration:none; 
}


@keyframes slideAnimation {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.looper {
  width: 100%;
  overflow: hidden;
}

.looper__innerList {
  display: flex;
  justify-content: center;
  width: fit-content;
}

.looper__innerList[data-animate="true"] 
.looper__listInstance {
  animation: slideAnimation linear infinite;   
}

.looper__listInstance {
  display: flex;
  width: max-content;

  animation: none;
}

@keyframes move {
  from {
    left: 100%;
  }
  to {
    left: -100%;
  }  
}

.moving_pic_1 {
  width: 100vw;
  height: auto;

  position: absolute;
  animation: move 40s infinite;
  animation-timing-function: linear;
  animation-delay: -20s;
}

.moving_pic_2 {
  width: 100vw;
  height: auto;

  position: absolute;
  animation: move 40s infinite;
  animation-timing-function: linear;
}

.background_size_pic {
  width: 100vw;
  height: auto;
  position: relative;
}

.middle_line_img {
  width: 70vw;
  height: 50px;
  margin-top: 30px;
}


.middle_jobicons_1 {  
  display: flex; 
  justify-content: space-between;

  margin-top: 30px;  
  height: fit-content;
}

.middle_jobicons_2 {  
  display: flex; 
  justify-content: space-around;

  /* margin-top: 30px;   */
  height: fit-content;
}

.middle_jobicons_block {
  width: 33%;
}

.middle_jobicons_block p {
  color: black;
  font-size: calc(5px + 2.5vw);
  white-space: nowrap;
}

.middle_strings p {
  color: black;
}

.present_text {
  font-size: 40px;
}

.company_name {
  font-size: 50px;
  text-decoration: underline;
}

.present_text_2 {
  font-size: 35px;
}


.middle_string {
  display: flex;
  width: fit-content;
}

.middle_icon img {
  height: auto; 
  width: 20vw;
}

.icon_and_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.middle_jobicons_2_half1 {
  display: flex;
  justify-content: center;  
  margin-left: 10vw;
  width: 50%;
}

.middle_jobicons_2_half2 {
  display: flex;
  justify-content: center;
  margin-right: 10vw;
  width: 50%;
}

.belt_pic {  
  display: flex;
  width: 100vw;
  position: relative;
  overflow: hidden;
}

.moving_gallery_item {
  height: 400px;
  width: auto;
  margin: 50px;
  padding: 10px;

  background-color: #b1b1b1;

  position: absolute;
  animation: move 20s infinite;
  animation-timing-function: linear;
}

.moving_gallery_item img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.carousel_container {
  padding: 100px;
  padding-bottom: 10px;
  font-family: "Comfortaa";
}

.carousel-root {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.carousel_item { 
  background-color: #58c4ae;
}

.carousel_item img {
  padding: 5px;
}

.images {
  display: flex;
  overflow: hidden;
  animation: slide 20s linear infinite;
}

.images img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.prev, .next {
  border: none;
  background-color: transparent;
  font-size: 1.5rem;
  cursor: pointer;
}

.prev {
  margin-right: 1rem;
}

.next {
  margin-left: 1rem;
}

.about_us_field p {
  color: black;
  font-size: 40px;
}

.contaier_button {
  display: flex;
}

.button_div {
  display: flex;
  padding: 10px;
  margin: auto; 
}

.button_div p {
  margin-bottom: 0;
}

.file_download img {
  width: 35px;
  height: 35px;
}

.about_us_text {
  padding-top: 10vh;
  padding-right: 10vw;
  padding-left: 10vw;
}

.about_us_p1{
  color: black;
  font-size: 25px;
  text-align: justify;
}

.bottom_line_img {
  width: 70vw;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.about_us_p2 {
  color: black;
  font-size: 25px;
  text-align: center;
}

.city_background_pic_container {
 display: flex;
 height: 500px;
 justify-content: center;
 overflow: hidden;
 
}

.upper_div_contacts {
  display: flex;
  flex-direction: column;
  display: inline-block;
}

.ud_contacts_row {
  width: 50vw;
  display: flex; 
}

.upper_div_contacts p{
  color: black;
  font-size: 25px; 
}

.ud_contact_type_pic img{
  height: 30px;
  padding-right: 10px;
}

.bottom_div {
  width: 100vw;
}

.bottom_div_contacts {
  display: flex;
  flex-direction: column;
  display: inline-block;
 
}

.bd_contacts_row {
  width: 50vw;
  display: flex;
}

.ud_contact_data {
  padding-left: 10px;
}

.ud_contact_data a{  
  color: black;
}

.bottom_div_contacts p{
  color: black;
  font-size: 25px;
 
}

.bd_contact_type{
  display: flex;
  padding-left: 10vw;
  padding-right: 5vw;
}

.bd_contact_type p{
  color: black;
  font-size: 25px;
  width: 100px ;
  text-align: left;
}

.bd_contact_type_pic img{
  height: 30px;
  padding-right: 10px;
}

.bd_contact_data {  
  width: fit-content;
  
  display: flex;
  justify-content: left;
}

.bd_contact_data p{  
  text-align: left;  
}

.bd_contact_data a{  
  color: black;
}

.footer {
  background-color: #367669;
  height: 100px;
  width: 100vw;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-family: 'Comfortaa';
}

.footer_current_year {
  padding-top: 15px;
  color: white;
  font-size: 25px;
}

.developer_info_row {
  display: flex;
  width: fit-content;
  justify-content: space-between; 
}

.developer_info_row p,a{
  font-size: calc(1em + 0,5vw);
  color: white;
  padding-right: 10px;
}

.developer_info_row a {
  color: white;
}

.mobile_variant {
  display: none;
}

.developer_info_row_developed_by {
  padding-right: 5px;
}


@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */ 
}

/* @media (max-width: 900px) or (orientation: portrait) or (-webkit-touch-callout: none) { */
/* @media only screen and (orientation: portrait) and (((min-device-width: 428px) and (max-device-height: 926px)) or ((min-device-width: 375px) and (max-device-height: 812px)) or ((min-device-width: 390) and (max-device-height: 844))) and (-webkit-device-pixel-ratio: 3){   */
/* @media (only screen and (orientation: portrait) and (min-device-width: 300px) and (max-device-height: 1792px) and (-webkit-device-pixel-ratio: 3)) or ((max-width: 900px)){ */
  @media only screen and (orientation: portrait) {

  @keyframes move {
    from {
      left: 200%;
    }
    to {
      left: -200%;
    }  
  }
 
  .hidden_on_mobile {
    display: none;
  }

  .mobile_variant {
    display: block;
  }

  .header {
    flex-direction: column;
    padding: 0;
  }

  .logo_wrapper {
    width: 100vw;
    height: auto;
    padding: 20px;
    padding-top: 0;
  }

  .logo img{
    padding: 0;
    padding-top: 10px;
  }

  .contacts_text {   
    display: none;
  }

  .content {
    /* width: max-content; */
    width: 100vw;
    padding: 0px;
    justify-content: center;
  }

  .under_header_div {
    width: 100vw;
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  
  .under_header_buttons_div {
    width: auto;
  }

  .under_header_buttons_div{
    padding: 0;
    margin: 0;

  }

  .button_div {
    width: fit-content;
    padding: 0;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .button_div p {
    font-size: calc(5px + 2vw);
    font-weight: bolder;
  }

  .button_div a {
    padding: 0;
  }

  .language_switcher{
    display: flex;
    justify-content: center;
    height: 40px;
    border-bottom: #282c34;
  }

  .background_size_pic {
    width: 200vw;
  }

  .moving_pic_1 {
    width: 200vw;
  }

  .moving_pic_2 {
    width: 200vw;
  }

  .ud_contact_data p{
    font-size: calc(5px + 2vmin);
  }

  .ud_contact_type_pic img{
    height: calc(5px + 2vmin);

  }

  .language_switcher img{
    height: calc(5px + 2vw);
  }

  .thumbs-wrapper {
    display: none;
  }

  .about_us_field {
    padding-top: 30px;
  }

  .middle_line {    
    display: flex;
    justify-content: center;
    width: 100vw;
  }

  .middle_line_img {
    margin: 0;
  }

  .middle_strings {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .middle_strings p {
    font-size: calc(5px + 5vw);
  }

  .middle_jobicons_1 {
    margin-top: 0;
  }

  .middle_icon img {    
    width: 33vw;
  }

  .middle_jobicons_block p {
    font-size: calc(5px + 2.5vw);
    width: auto;
  }

  .middle_jobicons_2_half1 {
    margin-left: 12px;
  }

  .middle_jobicons_2_half2 {
    margin-right: 12px;
  }

  .carousel_container {   
    width: 100vw;
    padding: 0;
  }

  .middle_div {
    display: flex;
    flex-direction: column;
    width: 100vw;
  }

  .file_download {
    padding: 10px;
    align-items: center;
  }

  .download_button_p_div {
    margin: auto;
  }

  .download_button_p {
    font-size: calc(5px + 10vw);
  }

  .about_us_text {
    padding-top: 10vh;
  }

  .about_us_p1 {
    font-size: calc(5px + 3vw);   
  }

  .city_background_pic_container {
    height: 235px;
  }
 

  .bottom_div_contacts {   
    padding-left: 10px;
    padding-right: 10px;
  }

  .bd_contact_type {
    padding: 0;
  }

  .bottom_div_contacts {
    display: flex;
    justify-content: space-around;
  }

  .bottom_div_contacts p{
    /* font-size: 10px; */
    font-size: calc(5px + 2.5vw);  
  }

  .bd_contacts_row {
    width: 70vw;
    align-self: center;
  }

  .bd_contact_type_label {
    width: 20vw;
  }

  .bd_contact_data {
    display: flex;
    justify-content: space-between;
  }

  .phonenum{
    white-space: nowrap;
  }

  .bd_contact_data p {
    width: 45vw;
  }
 
  .bd_contact_data_p {
    margin-left: auto;
    margin-right: auto;    
  }

  .bd_contact_data_addr_p {
    width: 50vw;
  }

  .developer_info_row {
    font-size: smaller;
  }

 
} 

